import { useMutation, useReactiveVar } from "@apollo/client";
import { IconMenu, Icons, Modal } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";
import {
  editFamilyFindingSearchPath,
  newPersonAsChildConnectionPath,
  relationshipsDashboardAdminChildPath,
  newFamilyFindingContactLogPath,
  familyFindingEffortsReportPersonPath,
} from "routes";

import { translationWithRoot } from "@components/T";
import { queryVariablesVar } from "@components/shared/BintiApolloProvider";

import DeleteSearch from "@graphql/mutations/DeleteFamilyFindingSearch.graphql";
import FamilyFindingSearchesQuery from "@graphql/queries/FamilyFindingSearches.graphql";

import { policy } from "@lib/graphqlHelpers";

const { t } = translationWithRoot("family_finding.searches.actions");
const { t: effortsReportT } = translationWithRoot(
  "family_finding.efforts_report"
);

/**
 * Renders the Actions component, which provides a menu for various operations
 * related to a family finding search, such as editing, deleting, and generating reports.
 */
const Actions = ({ search }) => {
  const queryVariables = useReactiveVar(queryVariablesVar);

  const { id, agencyHuman } = search;

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [deleteSearch, { loading }] = useMutation(DeleteSearch, {
    onCompleted: () => setShowDeleteModal(false),
    refetchQueries: [
      { query: FamilyFindingSearchesQuery, variables: queryVariables },
    ],
  });

  const perms = policy(search);

  return (
    <Fragment>
      <IconMenu
        icon={Icons.EllipsisVertical}
        menuDescription={t("description")}
        linkItems={[
          perms.mayUpdate() && {
            description: t("edit_search"),
            href: `${editFamilyFindingSearchPath(id)}?currentPage=1`,
          },
          perms.mayDestroy() && {
            description: t("delete_search"),
            onClick: () => setShowDeleteModal(true),
          },
          perms.mayCreateRelationship() && {
            description: t("add_connection"),
            href: newPersonAsChildConnectionPath(agencyHuman.childId),
          },
          perms.mayViewRelationships() && {
            description: t("view_relationships_dashboard"),
            href: relationshipsDashboardAdminChildPath(agencyHuman.childId),
          },
          perms.mayCreateContactLog() && {
            description: t("record_a_contact"),
            href: `${newFamilyFindingContactLogPath()}?child_agency_human_id=${
              agencyHuman.id
            }`,
          },
          perms.mayDownloadFamilyFindingEffortsReport() && {
            description: effortsReportT("generate_report"),
            href: `${familyFindingEffortsReportPersonPath(
              agencyHuman.id
            )}.xlsx`,
          },
        ].filter(Boolean)}
        rightAligned
      />
      <Modal
        title={t("delete_are_you_sure")}
        hidden={!showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onSubmit={() => deleteSearch({ variables: { searchId: id } })}
        submitting={loading}
        submitText={t("delete")}
        submitDangerButton
      />
    </Fragment>
  );
};

Actions.propTypes = {
  search: PropTypes.shape({
    id: PropTypes.string.isRequired,
    agencyHuman: PropTypes.shape({
      id: PropTypes.string.isRequired,
      childId: PropTypes.string.isRequired,
    }),
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default Actions;
