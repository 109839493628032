import { useQuery } from "@apollo/client";
import { Flex, InputDropdown, LoadingOverlay } from "@heart/components";
import gql from "graphql-tag";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

const { t } = translationWithRoot("conditional_requirements");

/**
 * Form component for conditional requirements.
 */
const ConditionalRequirementForm = ({
  applicationId,
  formState,
  setFormAttribute,
}) => {
  const { data, loading } = useQuery(
    gql`
      query ConditionalRequirementsForm($applicationId: ID!) {
        conditionalRequirementsFormData(applicationId: $applicationId) {
          assignees {
            label
            value
          }
          requirementTemplates {
            label
            value
          }
        }
      }
    `,
    {
      variables: { applicationId },
    }
  );

  return (
    <LoadingOverlay active={loading}>
      <Flex column>
        <InputDropdown
          label={t("assignee_id")}
          value={formState.assigneeId}
          values={data?.conditionalRequirementsFormData?.assignees || []}
          onChange={setFormAttribute("assigneeId")}
        />
        <InputDropdown
          label={t("requirement_template_id")}
          value={formState.requirementTemplateId}
          values={
            data?.conditionalRequirementsFormData?.requirementTemplates || []
          }
          onChange={setFormAttribute("requirementTemplateId")}
        />
      </Flex>
    </LoadingOverlay>
  );
};

ConditionalRequirementForm.propTypes = {
  applicationId: PropTypes.string,
  formState: PropTypes.object,
  setFormAttribute: PropTypes.func,
};

export default ConditionalRequirementForm;
