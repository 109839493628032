import { InputRadioGroup, Surface } from "@heart/components";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

import CaregiverAutocomplete from "./CaregiverAutocomplete";
import {
  MICROPHONE,
  RECORDING,
  TranscriptionSource,
  Caregiver,
} from "./constants";

const { t, T } = translationWithRoot("transcription.select_caregiver_page");

/**
 * The first page of the wizard for creating a new transcription process
 * where you select the family and the transcription source.
 */
const SelectCaregiverPage = ({
  transcriptionSource,
  setTranscriptionSource,
  caregiver,
  setCaregiver,
}: {
  transcriptionSource?: TranscriptionSource;
  setTranscriptionSource: (transcriptionSource: TranscriptionSource) => void;
  caregiver?: Caregiver;
  setCaregiver: (caregiver: Caregiver) => void;
}) => (
  <Surface title={t("title")} hideTitle>
    <CaregiverAutocomplete
      caregiver={caregiver}
      setCaregiver={setCaregiver}
      required
    />
    <InputRadioGroup
      label={t("transcription_source")}
      required
      values={[
        {
          value: MICROPHONE,
          label: t("transcription_source_microphone"),
        },
        {
          value: RECORDING,
          label: t("transcription_source_recording"),
        },
      ]}
      onChange={setTranscriptionSource}
      value={transcriptionSource}
      name="transcriptionSource"
    />
    <T t="recording_hint" />
  </Surface>
);

export default SelectCaregiverPage;
