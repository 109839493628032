import { useMountEffect } from "@react-hookz/web";
import { isNull } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import useLocation from "@lib/react-use/useLocation";
import { getSearchParamForAttribute, setSearchParams } from "@lib/searchParams";

/**
 * Hook for managing the current page search param.
 * Returns an object with `currentPage` with the value of the current
 * page and the `navigateToPage` function to navigate to a new page.
 */
const useCurrentPageSearchParam = ({ defaultPage }) => {
  const attribute = "currentPage";
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState();

  useMountEffect(() => {
    if (isNull(getSearchParamForAttribute({ attribute }))) {
      setSearchParams([{ attribute, value: defaultPage }]);
    }
  });

  useEffect(() => {
    const currentPageParam = getSearchParamForAttribute({ attribute });
    setCurrentPage(currentPageParam);
  }, [location.search]);

  const navigateToPage = ({ page }) => {
    history.pushState({}, document.title, window.location.href);
    setSearchParams([{ attribute, value: page }]);
  };

  return {
    currentPage,
    navigateToPage,
  };
};

useCurrentPageSearchParam.propTypes = {
  defaultPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

export default useCurrentPageSearchParam;
