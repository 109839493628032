import I18n from "i18n-js";
import PropTypes from "prop-types";
import { Fragment } from "react";

import ContainsTrustedHTML from "../contains_trusted_html/ContainsTrustedHTML";
import Notice from "../notifications/notice/Notice";
import Text from "../text/Text";
import BintiAssistIcon from "./BintiAssistIcon";

/**
 * Notification component to let users know that the content is from Binti
 * Assistant (i.e. AI), so it requires manual review.
 *
 * To allow easier usage from the backend, we allow passing in html directly
 * as a prop rendered by the backend containing links, etc.
 */
const BintiAssistNotice = ({
  children,
  html,
  trustedSource,
  "data-testid": testId,
}) => (
  <Notice
    testId={testId}
    title={
      <Fragment>
        <BintiAssistIcon alt={I18n.t("common.voice_navigation")} />{" "}
        <Text textStyle="emphasis-300">
          {I18n.t("common.voice_navigation")}
        </Text>
      </Fragment>
    }
    data-testid={testId}
    variant="ai"
  >
    {children || (
      <ContainsTrustedHTML html={html} trustedSource={trustedSource} />
    )}
  </Notice>
);

BintiAssistNotice.propTypes = {
  /**
   * The content to display in the notice. Provide only one of `children` or `html`.
   */
  children: PropTypes.node,
  /**
   * Trusted, rich HTML content to display in the notice coming from, e.g. a static
   * value in the backend (i.e. not user-generated content).
   *
   * Provide only one of `children` or `html`.
   */
  html: PropTypes.string,
  /**
   * Optional test ID.
   */
  "data-testid": PropTypes.string,
  /**
   * The trusted source of the HTML content. This is required to document why we trust
   * the HTML content and where it came from. Required when using the `html` prop.
   */
  trustedSource: PropTypes.string,
};

export default BintiAssistNotice;
