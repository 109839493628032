import { useQuery } from "@apollo/client";
import {
  Icons,
  Flex,
  LoadingOverlay,
  NodeDiagram,
  Toggle,
} from "@heart/components";
import { pull } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useState } from "react";

import AgencyHumanGenogramData from "@graphql/queries/AgencyHumanGenogramData.graphql";

import ViewRelationshipModal from "../relationships/ViewRelationshipModal";
import ChildNode from "./ChildNode";
import ConnectionNode from "./ConnectionNode";

const customNodes = { personNode: ConnectionNode, childNode: ChildNode };
const position = { x: 0, y: 0 };

/** A first iteration of a genogram, using only generational layers and
 * one degree relationships off of the child
 *
 * This is a super super super rough draft - eventually we'll need to change
 * a lot of the logic to implement the one degree relationships that will help
 * shape this into a better genogram shape. I'm not worrying too much about
 * the cleanliness of the code here because I'm going to be making so many changes
 * to it!
 */
const Genogram = ({ childAgencyHumanId }) => {
  const [reactFlowInstance, setReactFlowInstance] = useState();
  const [relationshipToView, setRelationshipToView] = useState();
  const { data: genogramData = { agencyHumanGenogramData: [] }, loading } =
    useQuery(AgencyHumanGenogramData, {
      variables: { agencyHumanId: childAgencyHumanId },
      /** Once the query is complete, we want to center the view of the genogram */
      onCompleted: () => setTimeout(reactFlowInstance?.fitView, 50),
    });

  let childAgencyHuman;
  let childNode;
  const nodes = [
    /** Adding a hidden node that connects to all the nodes of the graph.
     *
     * This is necessary as nodes without any edges attached do not take their
     * partition into account, which means we don't get generational lines
     * among folks that aren't connected via 1 degree relationships
     */
    {
      id: "hiddenConnectorNode",
      hidden: true,
      position,
      layoutOptions: { "partitioning.partition": 12 },
    },
  ];
  const dynamicEdges = [];
  const staticEdges = [];

  pull(Object.keys(genogramData.agencyHumanGenogramData), "__typename").forEach(
    key => {
      if (key === "keystoneAgencyHuman") {
        childAgencyHuman = genogramData.agencyHumanGenogramData[key];
        if (childAgencyHuman) {
          childNode = {
            id: "child",
            type: "childNode",
            data: childAgencyHuman,
            position,
            /** The child sits at layer 6, see models/relationship.rb for a
             * diagram of how the genogram layers fall
             */
            layoutOptions: { "partitioning.partition": 6 },
          };
          nodes.push(childNode);
        }
      } else {
        genogramData.agencyHumanGenogramData[key].forEach(relationship => {
          const node = {
            id: relationship.id,
            type: "personNode",
            data: {
              keystoneAgencyHumanId: childAgencyHumanId,
              relationship,
              setRelationshipToView,
            },
            position,
            layoutOptions: {
              "partitioning.partition":
                key === "fictiveKin"
                  ? 0
                  : parseInt(key.replace("layer", ""), 10),
            },
          };
          /** Adding an edge between all of the nodes that are included in the genogram and a hidden
           * node that connects to them all. This is necessary for the partitions to have an impact, as
           * nodes without any edges attached do not take their partition into account. These edges
           * will not be visible in the final genogram.
           */
          dynamicEdges.push({
            id: `${node.id}-connector`,
            source: "hiddenConnectorNode",
            target: node.id,
            type: "straight",
          });
          nodes.push(node);

          if (relationship.kinshipRelationship === "fictive_kin") {
            dynamicEdges.push({
              id: `${childNode.id}-${node.id}`,
              target: childNode.id,
              source: node.id,
              sourceHandle: "fictive-kin",
              targetHandle: "fictive-kin",
              type: "straight",
            });
          } else if (
            ["daughter", "son", "child"].includes(
              relationship.kinshipRelationship
            )
          ) {
            dynamicEdges.push({
              id: `${childNode.id}-${node.id}`,
              source: node.id,
              target: childNode.id,
              sourceHandle: "parent",
              targetHandle: "child",
              type: "step",
            });
          } else if (relationship.kinshipRelationship === "sibling")
            dynamicEdges.push({
              id: `${childNode.id}-${node.id}`,
              source: childNode.id,
              target: node.id,
              sourceHandle: "sibling",
              targetHandle: "sibling",
              type: "step",
            });
          else if (
            [
              "married",
              "divorced",
              "engaged",
              "dating",
              "cohabitating",
              "exes",
              "partner",
            ].includes(relationship.kinshipRelationship)
          )
            dynamicEdges.push({
              id: `${childNode.id}-${node.id}`,
              source: childNode.id,
              target: node.id,
              sourceHandle: "partner",
              targetHandle: "partner",
              type: "step",
            });
          else if (
            ["mother", "father", "parent"].includes(
              relationship.kinshipRelationship
            )
          )
            dynamicEdges.push({
              id: `${childNode.id}-${node.id}`,
              source: childNode.id,
              target: node.id,
              sourceHandle: "parent",
              targetHandle: "child",
              type: "step",
            });
        });
      }
    }
  );

  return (
    <LoadingOverlay active={loading}>
      <ViewRelationshipModal
        relationship={relationshipToView}
        keystoneAgencyHumanId={childAgencyHumanId}
        setRelationshipToView={setRelationshipToView}
      />
      <Flex justify="end">
        <Toggle
          LeftIcon={Icons.List}
          RightIcon={Icons.Sitemap}
          leftDescription="View Table"
          rightDescription="View Genogram"
          leftView="table"
          rightView="genogram"
        />
      </Flex>
      <NodeDiagram
        nodes={nodes}
        dynamicEdges={dynamicEdges}
        staticEdges={staticEdges}
        graphDirection="UP"
        nodeHeight={200}
        nodeWidth={200}
        customNodes={customNodes}
        onInit={instance => setReactFlowInstance(instance)}
        downloadFileName={`${
          childAgencyHuman?.fullName
        }_genogram_${DateTime.local().toFormat("yyyy-MM-dd")}`}
      />
    </LoadingOverlay>
  );
};
Genogram.propTypes = {
  childAgencyHumanId: PropTypes.string.isRequired,
};

export default Genogram;
