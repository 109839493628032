import { InputCheckbox, SurfaceFieldset } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import T from "@components/T";

import SingleAddressInput from "./SingleAddressInput";

/**
 * Address input compoment with separate inputs for physical and mailing address
 * Input names are set to play nice with specific active admin screens
 * Offloads the actual heavy lifting to SingleAddressInput.
 */
const Address = props => {
  const { address, usStateOptions, title } = props;
  const [mailingAddressSameAsPhysical, setMailingAddressSameAsPhysical] =
    useState(true);

  return (
    <div>
      <SurfaceFieldset title={title || <T t="address.primary_residence" />}>
        <SingleAddressInput
          usStateOptions={usStateOptions}
          address={address}
          fieldInputNames={{
            addressLine1:
              "application[applicant_agency_profile_attributes][address_street]",
            addressLine2:
              "application[applicant_agency_profile_attributes][address_street_line2]",
            city: "application[applicant_agency_profile_attributes][address_city]",
            primarySubdivision:
              "application[applicant_agency_profile_attributes][address_state]",
            postalCode:
              "application[applicant_agency_profile_attributes][address_zipcode]",
          }}
        />
        <InputCheckbox
          label={<T t="address.mailing_address_same_as_physical" />}
          value={mailingAddressSameAsPhysical}
          name={
            "application[applicant_agency_profile_attributes][mailing_address_is_same_as_physical]"
          }
          id={
            "application[applicant_agency_profile_attributes][mailing_address_is_same_as_physical]"
          }
          onChange={checked => {
            setMailingAddressSameAsPhysical(checked);
          }}
        />
        {!mailingAddressSameAsPhysical && (
          <SingleAddressInput
            usStateOptions={usStateOptions}
            address={{}}
            useMailingLabels={true}
            fieldInputNames={{
              addressLine1:
                "application[applicant_agency_profile_attributes][mailing_address_street]",
              addressLine2:
                "application[applicant_agency_profile_attributes][mailing_address_street_line2]",
              city: "application[applicant_agency_profile_attributes][mailing_address_city]",
              primarySubdivision:
                "application[applicant_agency_profile_attributes][mailing_address_state]",
              postalCode:
                "application[applicant_agency_profile_attributes][mailing_address_zipcode]",
            }}
          />
        )}
      </SurfaceFieldset>
    </div>
  );
};

Address.propTypes = {
  /** Address of the applicant */
  address: PropTypes.shape({
    addressLine1: PropTypes.string,
    city: PropTypes.string,
    primarySubdivisionCode: PropTypes.string,
    primarySubdivisionName: PropTypes.string,
    postalCode: PropTypes.string,
  }),
  title: PropTypes.string,
  /** US States options for user to choose */
  usStateOptions: PropTypes.array,
};

export default Address;
