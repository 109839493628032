import {
  InputFilterable,
  InputTextWithAddressAutocomplete,
  InputText,
} from "@heart/components";
import I18n from "i18n-js";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Fragment, useMemo, useState } from "react";

import T from "@components/T";

import useFeatureFlag from "@lib/useFeatureFlag";

import InputZipcode from "./InputZipcode";

const buildStateOptions = usStateOptions =>
  usStateOptions.map(
    // eslint-disable-next-line camelcase
    ({ key, i18nPrefix, i18n_prefix }) => ({
      // depending if the caller uses `camelize_props`, we may need different keys.
      // eslint-disable-next-line camelcase
      label: I18n.t(`${i18nPrefix || i18n_prefix}.${key}`),
      value: key.toUpperCase(),
    })
  );

/**
 * Historically some callers called this component with the state name rather than code.
 * This function resolves code from name or vice versa so both are populated.
 */
const resolveStateCodesAndNames = (initialAddress, stateOptions) => {
  if (isEmpty(initialAddress)) {
    return initialAddress;
  }
  const address = initialAddress;

  // if we have a code for the state, we want to populate the long name of that state
  // by finding the state in our list.
  if (address.primarySubdivisionCode) {
    address.primarySubdivisionCode =
      address.primarySubdivisionCode.toUpperCase();
    const foundState = stateOptions.find(
      state => state.value === address.primarySubdivisionCode
    );
    address.primarySubdivisionName = foundState?.label;
  }

  // Conversely, if we do not have a code, but do have a name, we want to find the code
  // associated with the state of that name.
  if (
    !address.primarySubdivisionCode &&
    isEmpty(address.primarySubdivisionName)
  ) {
    const foundState = stateOptions.find(
      state => state.label === address.primarySubdivisionName
    );
    address.primarySubdivisionCode = foundState?.value;
  }

  return address;
};

/**
 * An input screen for a single address with autocomplete
 */
const SingleAddressInput = ({
  address: initialAddress = {},
  fieldInputNames = {
    addressLine1: "addressLine1",
    addressLine2: "addressLine2",
    city: "city",
    primarySubdivision: "primarySubdivision",
    postalCode: "postalCode",
  },
  usStateOptions,
  useMailingLabels = false,
}) => {
  const stateOptions = useMemo(
    () => buildStateOptions(usStateOptions),
    [usStateOptions]
  );
  const hydratedAddress = useMemo(
    () => resolveStateCodesAndNames(initialAddress, stateOptions),
    [initialAddress, stateOptions]
  );
  const [address, setAddress] = useState(hydratedAddress);

  const { flag: ffUseAutocompleteForAddressInputs } = useFeatureFlag(
    "ff_use_autocomplete_for_address_inputs"
  );

  const maybeMailingPrefix = useMailingLabels ? "mailing_" : "";
  const labels = {
    street1: `address.${maybeMailingPrefix}street_address`,
    street2: `address.${maybeMailingPrefix}street_address_line2`,
    city: `address.${maybeMailingPrefix}city`,
    state: `address.${maybeMailingPrefix}state`,
    zipCode: `address.${maybeMailingPrefix}zip_code`,
  };

  return (
    <Fragment>
      <InputTextWithAddressAutocomplete
        label={<T t={labels.street1} />}
        type="text"
        name={fieldInputNames.addressLine1}
        id={fieldInputNames.addressLine1}
        autocompleteEnabled={Boolean(ffUseAutocompleteForAddressInputs)}
        onAddressPicked={pickedAddress =>
          setAddress({ ...address, ...pickedAddress })
        }
        required
        value={address?.addressLine1 || ""}
      />
      <InputText
        label={<T t={labels.street2} />}
        type="text"
        name={fieldInputNames.addressLine2}
        id={fieldInputNames.addressLine2}
        value={address?.addressLine2 || ""}
      />
      <InputText
        label={<T t={labels.city} />}
        type="text"
        name={fieldInputNames.city}
        id={fieldInputNames.city}
        required
        value={address?.city || ""}
      />
      <InputFilterable
        label={<T t={labels.state} />}
        name={fieldInputNames.primarySubdivision}
        id={fieldInputNames.primarySubdivision}
        required
        onChange={({ value, label }) =>
          setAddress({
            ...address,
            ...{
              primarySubdivisionName: label,
              primarySubdivisionCode: value,
            },
          })
        }
        options={stateOptions}
        value={
          address?.primarySubdivisionName
            ? {
                label: address.primarySubdivisionName,
                value: address.primarySubdivisionCode,
              }
            : []
        }
      />
      <InputZipcode
        label={<T t={labels.zipCode} />}
        name={fieldInputNames.postalCode}
        id={fieldInputNames.postalCode}
        required
        value={address?.postalCode || ""}
      />
    </Fragment>
  );
};

SingleAddressInput.propTypes = {
  /** Address of the applicant */
  address: PropTypes.shape({
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    primarySubdivisionCode: PropTypes.string,
    primarySubdivisionName: PropTypes.string,
    postalCode: PropTypes.string,
  }),
  fieldInputNames: PropTypes.shape({
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    primarySubdivision: PropTypes.string,
    postalCode: PropTypes.string,
  }),
  /** US States options for user to choose */
  usStateOptions: PropTypes.array.isRequired,

  /** Label the fields with "Mailing X", i.e. "Mailing Street Address" */
  useMailingLabels: PropTypes.bool,
};

export default SingleAddressInput;
