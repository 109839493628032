import { Flex, Icons, Text, Notice } from "@heart/components";
import Clickable from "@heart/components/clickable/Clickable";
import { useMediaQuery } from "@react-hookz/web";
import classNames from "classnames";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { createContext, useEffect, useState } from "react";

import styles from "./Layout.module.scss";

export const LayoutContext = createContext({
  desktopCollapsed: false,
  setDesktopCollapsed: null,
  isMobile: false,
});

/** A wrapper that adds our Layout React Context, which is used
 * to communicate with instances of the Sidebar component
 */
export const LayoutContextProvider = ({
  children,
  desktopCollapsed,
  setDesktopCollapsed,
  isMobile,
}) => (
  <LayoutContext.Provider
    value={{ desktopCollapsed, isMobile, setDesktopCollapsed }}
  >
    {children}
  </LayoutContext.Provider>
);
LayoutContextProvider.propTypes = {
  children: PropTypes.node,
  desktopCollapsed: PropTypes.bool,
  setDesktopCollapsed: PropTypes.func,
  isMobile: PropTypes.bool,
};

/** Our page level layout component!
 * This component will take in a page's main content, and optionally a sidebar, and handle the
 * responsiveness of each element
 *
 * Layout options:
 * - **Single Column Layout** - Used for pages where no sidebar is needed
 * - **Narrow Single Column Layout** - Used for pages where content is intentionally more narrow.
 * (e.g. login page)
 * - **Two Column Layout** - Used for pages with a traditional sidebar
 * - **Two Column Layout with Wide Sidebar** - Used for pages with content heavy sidebars, allowing
 * more space for that sidebar content in comparison to the space allocated to the main content
 *
 * When using a layout with a sidebar, the sidebar prop provided should be an instance of the
 * Sidebar component
 */
const Layout = ({
  pageTitle,
  subtitle,
  pageNotice,
  breadcrumbs,
  secondary,
  sidebar = {},
  main: { content: mainContent, narrow },
}) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [mobileCollapsed, setMobileCollapsed] = useState(isMobile);
  const [desktopCollapsed, setDesktopCollapsed] = useState(false);
  const {
    title: sidebarTitle = "",
    content: sidebarContent,
    fullHeight,
    opaque,
    wide,
  } = sidebar;
  const MobileSidebarIcon = mobileCollapsed
    ? Icons.ChevronDown
    : Icons.ChevronUp;

  const Title = () => (
    <Flex column gap="300">
      <If condition={Boolean(pageTitle)}>
        <Text as="h1" textStyle="emphasis-300">
          {pageTitle}
        </Text>
      </If>
      <If condition={Boolean(subtitle)}>
        <Text as="h2" textStyle="supporting-100" textColor="neutral-500">
          {subtitle}
        </Text>
      </If>
    </Flex>
  );

  useEffect(() => {
    /** If the viewport changes, set the sidebar's state to
     * the viewport's default collapsed state
     */
    if (isMobile) {
      setDesktopCollapsed(false);
      setMobileCollapsed(true);
    } else {
      setDesktopCollapsed(false);
      setMobileCollapsed(false);
    }
  }, [isMobile]);

  const hasSidebar = !isEmpty(sidebarContent);
  const pageHeader =
    breadcrumbs || pageTitle ? (
      <Flex
        column
        gap="300"
        className={classNames(styles.pageHeader, {
          [styles.withWideSidebar]: fullHeight && wide && !desktopCollapsed,
          [styles.withNarrowSidebar]: fullHeight && !wide && !desktopCollapsed,
          [styles.withSidebarCollapsed]: desktopCollapsed,
        })}
      >
        {breadcrumbs}
        <If condition={(pageTitle || subtitle) && secondary}>
          <Flex align="center" justify="space-between">
            <Title />
            {secondary}
          </Flex>
        </If>
        <If condition={(pageTitle || subtitle) && !secondary}>
          <Title />
        </If>
        <If condition={!(pageTitle || subtitle) && secondary}>
          <Flex justify="end">{secondary}</Flex>
        </If>
        <If condition={pageNotice}>
          <Notice title={pageNotice.title}>
            <Text textStyle="supporting-100">{pageNotice.body}</Text>
          </Notice>
        </If>
      </Flex>
    ) : null;

  return (
    <LayoutContextProvider
      desktopCollapsed={desktopCollapsed}
      setDesktopCollapsed={setDesktopCollapsed}
      isMobile={isMobile}
    >
      <Flex
        column
        gap="300"
        className={classNames(styles.layout, {
          [styles.layoutWrapperWithSidebar]: hasSidebar && !fullHeight,
          [styles.layoutWrapperWithFullHeightSidebar]: hasSidebar && fullHeight,
          [styles.layoutWrapperWithoutSidebar]: !hasSidebar,
        })}
      >
        {pageHeader}
        <If condition={hasSidebar}>
          <div
            className={classNames(styles.sidebar, {
              [styles.opaque]: opaque,
              [styles.wideSidebar]: wide && !desktopCollapsed,
              [styles.narrowSidebar]: !wide && !desktopCollapsed,
              [styles.collapsedSidebar]: desktopCollapsed,
            })}
          >
            <Clickable
              /** This is specific to mobile collapsibility, desktop collapsibility is
               * handled by the Sidebar itself
               */
              aria-hidden={!isMobile}
              aria-expanded={!mobileCollapsed}
              aria-controls="page_sidebar"
              onClick={() => {
                setMobileCollapsed(!mobileCollapsed);
              }}
              className={styles.toggle}
            >
              <Flex justify="space-between" align="center" fullWidth>
                <Text>{mobileCollapsed ? sidebarTitle : ""}</Text>
                <MobileSidebarIcon />
              </Flex>
            </Clickable>
            <span
              id="page_sidebar"
              aria-hidden={mobileCollapsed}
              className={classNames({ [styles.collapsed]: mobileCollapsed })}
            >
              {sidebarContent}
            </span>
          </div>
        </If>
        <div
          className={classNames(styles.mainContent, {
            [styles.withWideSidebar]: wide && !desktopCollapsed,
            [styles.withNarrowSidebar]: !wide && !desktopCollapsed,
            [styles.narrowMainContent]:
              narrow && !hasSidebar && !desktopCollapsed,
            [styles.regularMainContent]:
              (!narrow || hasSidebar) && !desktopCollapsed,
            [styles.withSidebarCollapsed]: desktopCollapsed,
          })}
        >
          <Flex column gap="300">
            {mainContent}
          </Flex>
        </div>
      </Flex>
    </LayoutContextProvider>
  );
};
Layout.propTypes = {
  /** A title for the page */
  pageTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** An optional notice for the page if an object containing a title and body
   * is passed to pageNotice */
  pageNotice: PropTypes.shape({
    /** A title for the Notice */
    title: PropTypes.string,
    /** Content shown in the body of the Notice */
    body: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  /** Subtitle of the Layout, displayed underneath the title */
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** An instance of <Breadcrumbs/> indicating where in the information
   * architecture this page lives
   */
  breadcrumbs: PropTypes.node,
  sidebar: PropTypes.shape({
    /** A title for the sidebar, displayed at the top of the sidebar on
     * desktop or next to the sidebar toggle on mobile when provided
     */
    title: PropTypes.string,
    /** Content for the sidebar of the page */
    content: PropTypes.node,
    /** Whether the sidebar should take up the full height of the page. Defaults to false */
    fullHeight: PropTypes.bool,
    /** Whether the background of the sidebar should be opaque. Defaults to false */
    opaque: PropTypes.bool,
    /** Whether the sidebar of the page should be wide. Defaults to false */
    wide: PropTypes.bool,
  }),
  main: PropTypes.shape({
    /** Content for the main section of the page */
    content: PropTypes.node.isRequired,
    /** Whether the main content of the page should be narrow. Defaults to false */
    narrow: ({ sidebar, narrow }) => {
      if (narrow) {
        if (sidebar)
          return new Error(
            "Sidebar is not compatible with a narrow page layout"
          );
        if (typeof narrow !== "boolean")
          return new Error(
            "Invalid prop type `main.narrow` supplied to `Layout`, expected `boolean`"
          );
      }
      return null;
    },
  }),
  /** Secondary content - usually a call-to-action or a filter input,
   * displayed in the top right */
  secondary: PropTypes.node,
};

export default Layout;
