import { Actions, Flex, Modal } from "@heart/components";
import useCurrentPageSearchParam from "@heart/components/layout/page_layout/useCurrentPageSearchParam";
import PropTypes from "prop-types";
import { Fragment, useContext, useEffect, useState } from "react";

import { translationWithRoot } from "@components/T";

import generateId from "@lib/generateId";
import preventDefault from "@lib/preventDefault";

import { WizardPageContext } from "./Wizard";

const { t } = translationWithRoot("heart.components.wizard");

const WizardPage = ({ confirmBeforeCancel, actionsProps, children }) => {
  const { currentPage, navigateToPage } = useCurrentPageSearchParam({
    defaultPage: 0,
  });
  const [parsedCurrentPage, setParsedCurrentPage] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [secondaryIsSubmitting, setSecondaryIsSubmitting] = useState(false);
  const [confirmationModalHidden, setConfirmationModalHidden] = useState(true);

  const { pages } = useContext(WizardPageContext);

  useEffect(() => {
    setParsedCurrentPage(parseInt(currentPage, 10));
  }, [currentPage]);

  /** Bind the page buttons to changing the current page of the Wizard */
  const actionForPage = ({ action }) =>
    actionsProps[`${action}Action`]
      ? async args => {
          const isPrimary = action === "primary";
          if (isPrimary) setSubmitting(true);
          else setSecondaryIsSubmitting(true);
          await actionsProps[`${action}Action`](args);
          const newPageNumber = isPrimary
            ? parsedCurrentPage + 1
            : parsedCurrentPage - 1;
          if (newPageNumber > -1 && newPageNumber < pages.length) {
            navigateToPage({ page: newPageNumber });
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }
          setSubmitting(false);
          setSecondaryIsSubmitting(false);
        }
      : undefined;

  const cancelAction = async event => {
    await actionForPage({
      actionsProps,
      action: "cancel",
    })(event);
    setConfirmationModalHidden(true);
  };

  const formId = generateId();

  return (
    <Fragment>
      <Modal
        title={t("are_you_sure")}
        submitDangerButton
        submitting={submitting}
        submitText={t("discard_changes")}
        onCancel={() => setConfirmationModalHidden(true)}
        submitHref={actionsProps.cancelHref}
        onSubmit={cancelAction}
        hidden={confirmationModalHidden}
      >
        {t("unsaved_changes_warning")}
      </Modal>
      <Flex
        column
        gap="300"
        autoComplete="off"
        as="form"
        id={formId}
        onSubmit={preventDefault(
          actionForPage({
            actionsProps,
            action: "primary",
          })
        )}
        data-heart-component="WizardPage"
      >
        {children}
        <Actions
          {...actionsProps}
          formId={formId}
          secondaryIsSubmitting={secondaryIsSubmitting}
          isSubmitting={submitting}
          /** We want to use the onSubmit that accompanies our <form/> as it enables
           * us to use built in form validations rather than using the primaryAction
           */
          primaryAction={undefined}
          secondaryAction={actionForPage({
            actionsProps,
            action: "secondary",
          })}
          cancelHref={confirmBeforeCancel ? undefined : actionsProps.cancelHref}
          cancelAction={
            confirmBeforeCancel
              ? () => setConfirmationModalHidden(false)
              : cancelAction
          }
        />
      </Flex>
    </Fragment>
  );
};
WizardPage.propTypes = {
  /** See Actions component for prop details */
  actionsProps: PropTypes.object.isRequired,
  /** When true, a modal will appear when the user clicks the cancel button for the page,
   * warning users that all unsaved changes will be lost */
  confirmBeforeCancel: PropTypes.bool,
  /** Title of the page */
  pageTitle: PropTypes.string.isRequired,
  /** How complete is this page? Range: 0-100 */
  progress: PropTypes.number.isRequired,
  /** If all required fields have been completed but the user still needs to
   * push submit on the page to save the information, progressIncomplete
   * will keep the section from being marked as completed
   */
  progressIncomplete: PropTypes.bool,
  children: PropTypes.node,
};

export default WizardPage;
