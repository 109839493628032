import classNames from "classnames";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { forwardRef } from "react";

import Clickable from "../clickable/Clickable";
import styles from "./Icon.module.scss";

/**
 * Icons!  Heart has an `<Icon>` component that is not exported in the public
 * interface of Heart - if you get a design that uses an icon not in our set,
 * add it here!  We want to keep our icon set constrained and it's much easier
 * to statically analyze which icons are in use when we only use exports from
 * this file.
 *
 * ```js
 * import { Icons } from "@heart/components";
 * return <Icons.Plus />;
 * ```
 *
 * KNOWN ISSUES:
 *   * These are FontAwesome icons and they won't look exactly like the ones you
 * see in Figma designs.  Find the closest alternative
 * [from this site](https://fontawesome.com/v5/search) and we'll use it until
 * we get new glyphs:
 */
const Icon = forwardRef(
  ({ icon, description, href, onClick, variant, ...props }, ref) => {
    const iconArray = Array.isArray(icon) ? icon : [`fa-${icon}`];
    const iconClassName = classNames(styles.icon, ...iconArray);

    if (href || onClick) {
      return (
        <Clickable
          aria-label={description}
          title={description}
          ref={ref}
          href={href}
          onClick={onClick}
          anchorClassname={classNames(styles.clickableIconAnchor, {
            [styles[variant]]: variant,
          })}
          buttonClassname={classNames(styles.clickableIconButton, {
            [styles[variant]]: variant,
          })}
          data-heart-component="ClickableIcon"
          {...props}
        >
          {<i className={iconClassName} />}
        </Clickable>
      );
    }

    return <i className={iconClassName} title={description} />;
  }
);
Icon.displayName = "Icon";

Icon.propTypes = {
  /** Either a string without the `fa-` prefix for shorthand, or an array
   * of FontAwesome classnames */
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  /** Whether the clickable icon is disabled. Note: disabled cannot be used
   * in conjunction with an `href`
   */
  disabled: props => {
    if (props.disabled && props.href) {
      return new Error("disabled and href props are mutually exclusive");
    }
    if (!isEmpty(props.disabled) && typeof props.disabled !== "boolean") {
      return new Error(
        "Failed prop type: Invalid prop disabled supplied to Icon"
      );
    }
    return undefined;
  },
  /** Location, if this icon is clickable and redirects the user */
  href: PropTypes.string,
  /** onClick handler, if this icon is clickable and executes a callback. */
  onClick: PropTypes.func,
  /** `description` indicates to screen readers what the purpose of this icon is
   * and puts useful hover text on the button.
   */
  description: props => {
    if ((props.onClick || props.href) && isEmpty(props.description)) {
      return new Error("description prop is required for clickable icons");
    }
    if (!isEmpty(props.description) && typeof props.description !== "string") {
      return new Error(
        "Failed prop type: Invalid prop description supplied to Icon"
      );
    }
    return undefined;
  },
  /** Which variant? */
  variant: PropTypes.oneOf(["activated"]),
  /** Test ID for Cypress or Jest */
  "data-testid": PropTypes.string,
};

export default Icon;

/* eslint-disable react/display-name */
export const ArrowLeft = forwardRef((props, ref) => (
  <Icon icon="arrow-left" {...props} ref={ref} />
));
export const ArrowRight = forwardRef((props, ref) => (
  <Icon icon="arrow-right" {...props} ref={ref} />
));
export const Ban = forwardRef((props, ref) => (
  <Icon icon="ban" {...props} ref={ref} />
));
export const Bars = forwardRef((props, ref) => (
  <Icon icon="bars" {...props} ref={ref} />
));
export const Calendar = forwardRef((props, ref) => (
  <Icon icon={["fa-calendar-alt", "far"]} {...props} ref={ref} />
));
export const CaretDown = forwardRef((props, ref) => (
  <Icon icon="caret-down" {...props} ref={ref} />
));
export const CaretUp = forwardRef((props, ref) => (
  <Icon icon="caret-up" {...props} ref={ref} />
));
export const Check = forwardRef((props, ref) => (
  <Icon icon="check" {...props} ref={ref} />
));
export const ChevronDown = forwardRef((props, ref) => (
  <Icon icon="chevron-down" {...props} ref={ref} />
));
export const ChevronLeft = forwardRef((props, ref) => (
  <Icon icon="chevron-left" {...props} ref={ref} />
));
export const ChevronRight = forwardRef((props, ref) => (
  <Icon icon="chevron-right" {...props} ref={ref} />
));
export const ChevronUp = forwardRef((props, ref) => (
  <Icon icon="chevron-up" {...props} ref={ref} />
));
export const CommentAlt = forwardRef((props, ref) => (
  <Icon icon="comment-alt" {...props} ref={ref} />
));
export const AngleLeft = forwardRef((props, ref) => (
  <Icon icon="angle-left" {...props} ref={ref} />
));
export const AngleRight = forwardRef((props, ref) => (
  <Icon icon="angle-right" {...props} ref={ref} />
));
export const AngleDoubleLeft = forwardRef((props, ref) => (
  <Icon icon="angle-double-left" {...props} ref={ref} />
));
export const AngleDoubleRight = forwardRef((props, ref) => (
  <Icon icon="angle-double-right" {...props} ref={ref} />
));
export const CheckCircle = forwardRef((props, ref) => (
  <Icon icon="check-circle" {...props} ref={ref} />
));
export const CopyToClipboard = forwardRef((props, ref) => (
  <Icon icon="copy" {...props} ref={ref} />
));
export const Edit = forwardRef((props, ref) => (
  <Icon icon="edit" {...props} ref={ref} />
));
export const Ellipsis = forwardRef((props, ref) => (
  <Icon icon="ellipsis-h" {...props} ref={ref} />
));
export const EllipsisVertical = forwardRef((props, ref) => (
  <Icon icon="ellipsis-v" {...props} ref={ref} />
));
export const Envelope = forwardRef((props, ref) => (
  <Icon icon="envelope" {...props} ref={ref} />
));
export const ExclamationTriangle = forwardRef((props, ref) => (
  <Icon icon="exclamation-triangle" {...props} ref={ref} />
));
export const Eye = forwardRef((props, ref) => (
  <Icon icon="eye" {...props} ref={ref} />
));
export const EyeSlash = forwardRef((props, ref) => (
  <Icon icon="eye-slash" {...props} ref={ref} />
));
export const File = forwardRef((props, ref) => (
  <Icon icon="file" {...props} ref={ref} />
));
export const FileDownload = forwardRef((props, ref) => (
  <Icon icon="file-download" {...props} ref={ref} />
));
export const FileUpload = forwardRef((props, ref) => (
  <Icon icon="file-upload" {...props} ref={ref} />
));
export const Filter = forwardRef((props, ref) => (
  <Icon icon="filter" {...props} ref={ref} />
));
export const Hourglass = forwardRef((props, ref) => (
  <Icon icon="hourglass-half" {...props} ref={ref} />
));
export const Image = forwardRef((props, ref) => (
  <Icon icon="image" {...props} ref={ref} />
));
export const Images = forwardRef((props, ref) => (
  <Icon icon="images" {...props} ref={ref} />
));
export const InfoCircle = forwardRef((props, ref) => (
  <Icon icon="info-circle" {...props} ref={ref} />
));
export const Language = forwardRef((props, ref) => (
  <Icon icon={["fa-language", "fa-lg", "fas"]} {...props} ref={ref} />
));
export const List = forwardRef((props, ref) => (
  <Icon icon="list" {...props} ref={ref} />
));
export const Lock = forwardRef((props, ref) => (
  <Icon icon="arrow-left" {...props} ref={ref} />
));
export const Microphone = forwardRef((props, ref) => (
  <Icon icon="microphone" {...props} ref={ref} />
));
export const MicrophoneSlash = forwardRef((props, ref) => (
  <Icon icon="microphone-slash" {...props} ref={ref} />
));
export const Minus = forwardRef((props, ref) => (
  <Icon icon="minus" {...props} ref={ref} />
));
export const PaperPlane = forwardRef((props, ref) => (
  <Icon icon="paper-plane" {...props} ref={ref} />
));
export const Parachute = forwardRef((props, ref) => (
  <Icon icon="parachute-box" {...props} ref={ref} />
));
export const Pause = forwardRef((props, ref) => (
  <Icon icon="pause" {...props} ref={ref} />
));
export const PenSquare = forwardRef((props, ref) => (
  <Icon icon="pen-square" {...props} ref={ref} />
));
export const Pencil = forwardRef((props, ref) => (
  <Icon icon="pencil-alt" {...props} ref={ref} />
));
export const Phone = forwardRef((props, ref) => (
  <Icon icon="phone" {...props} ref={ref} />
));
export const Plus = forwardRef((props, ref) => (
  <Icon icon="plus" {...props} ref={ref} />
));
export const Search = forwardRef((props, ref) => (
  <Icon icon="search" {...props} ref={ref} />
));
export const Sitemap = forwardRef((props, ref) => (
  <Icon icon="sitemap" {...props} ref={ref} />
));
export const Sort = forwardRef((props, ref) => (
  <Icon icon="sort" {...props} ref={ref} />
));
export const Spinner = forwardRef((props, ref) => (
  <Icon icon={["fa-circle-notch", "fa-spin", "fa-fw"]} {...props} ref={ref} />
));
export const Star = forwardRef((props, ref) => (
  <Icon icon="star" {...props} ref={ref} />
));
export const StickyNote = forwardRef((props, ref) => (
  <Icon icon="sticky-note" {...props} ref={ref} />
));
export const Tasks = forwardRef((props, ref) => (
  <Icon icon="tasks" {...props} ref={ref} />
));
export const Times = forwardRef((props, ref) => (
  <Icon icon="times" {...props} ref={ref} />
));
export const TimesCircle = forwardRef((props, ref) => (
  <Icon icon="times-circle" {...props} ref={ref} />
));
export const Trash = forwardRef((props, ref) => (
  <Icon icon="trash" {...props} ref={ref} />
));
export const VolumeUp = forwardRef((props, ref) => (
  <Icon icon="volume-up" {...props} ref={ref} />
));
/* eslint-enable react/display-name */
