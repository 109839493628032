// import { gql, useQuery } from "@apollo/client";
import { Button } from "@heart/components";
import { useState } from "react";

import ConditionalRequirementCreateModal from "@components/conditional_requirements/ConditionalRequirementCreateModal";

import BintiPropTypes from "@lib/BintiPropTypes";

/**
 * Button to add an conditional requirement.
 */
const AddConditionalRequirementButton = ({ applicationId }) => {
  // const { data } = useQuery(
  //   gql`
  //     query AddConditionalRequirementButton($applicationId: ID!) {
  //       featureFlag(flag: "ff_additional_requirements_10_2024")
  //       mayGenerateRequirements: permission(
  //         action: "generate_requirements"
  //         resourceName: "application"
  //         resourceId: $applicationId
  //       )
  //     }
  //   `,
  //   { variables: { applicationId } }
  // );
  const [showConditionalRequirementModal, setShowConditionalRequirementModal] =
    useState(false);

  // <If condition={data?.featureFlag && data?.mayGenerateRequirements}></If>
  return (
    <If condition={false}>
      <Button
        variant="secondary"
        onClick={() => setShowConditionalRequirementModal(true)}
      >
        Add Conditional Requirement
      </Button>
      <If condition={showConditionalRequirementModal}>
        <ConditionalRequirementCreateModal
          applicationId={applicationId}
          onCancel={() => setShowConditionalRequirementModal(false)}
        />
      </If>
    </If>
  );
};

AddConditionalRequirementButton.propTypes = {
  applicationId: BintiPropTypes.ID,
};

export default AddConditionalRequirementButton;
