export const MICROPHONE = "microphone";
export const RECORDING = "recording";

export const CASE_NOTE = "case_note";
export const FORM = "form";

export type TranscriptionType = "case_note" | "form";
export type TranscriptionSource = "microphone" | "recording";

export interface Caregiver {
  value: string;
  label: string;
  applicationId: string;
  userAgencyProfileId: string;
}
