import { gql, useMutation } from "@apollo/client";
import { Modal } from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import _ from "lodash";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

import BintiPropTypes from "@lib/BintiPropTypes";
import preventDefault from "@lib/preventDefault";

import ConditionalRequirementForm from "./ConditionalRequirementForm";

const { t } = translationWithRoot("conditional_requirements");

const initialFormState = {};

const CreateConditionalRequirementMutation = gql`
  mutation CreateConditionalRequirement(
    $input: CreateConditionalRequirementInput!
  ) {
    createConditionalRequirement(input: $input) {
      conditionalRequirement {
        id
      }
    }
  }
`;

/**
 * Form for creating an conditional requirement.
 */
const ConditionalRequirementCreateModal = ({ applicationId, onCancel }) => {
  const { formState, setFormAttribute } = useBintiForm(initialFormState);

  const [createConditionalRequirement, { loading }] = useMutation(
    CreateConditionalRequirementMutation
  );

  const onSubmit = preventDefault(() =>
    createConditionalRequirement({
      variables: {
        input: {
          ..._.omit(formState, "__typename"),
        },
      },
    })
  );

  return (
    <Modal
      title={t("title")}
      onSubmit={onSubmit}
      onCancel={onCancel}
      submitting={loading}
    >
      <ConditionalRequirementForm
        applicationId={applicationId}
        formState={formState}
        setFormAttribute={setFormAttribute}
      />
    </Modal>
  );
};

ConditionalRequirementCreateModal.propTypes = {
  applicationId: BintiPropTypes.ID,
  onCancel: PropTypes.func,
};

export default ConditionalRequirementCreateModal;
