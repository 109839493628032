import { Icons, Sidebar, sidebarItem } from "@heart/components";
import useCurrentPageSearchParam from "@heart/components/layout/page_layout/useCurrentPageSearchParam";
import PropTypes from "prop-types";
import React from "react";
import {
  personPath,
  editPersonPath,
  familyFindingDocumentsPersonPath,
} from "routes";

import { translationWithRoot } from "@components/T";

import useFeatureFlag from "@lib/useFeatureFlag";

import useAgencyHumanPermissions from "./useAgencyHumanPermissions";

const { t } = translationWithRoot("agency_human.sidebar");

/** A sidebar for our AgencyHuman profile page */
const AgencyHumanSidebar = ({ avatar, label, agencyHumanId }) => {
  const { currentPage, navigateToPage } = useCurrentPageSearchParam({});
  const { flag: ffIntegratedSearch } = useFeatureFlag(
    "ff_integrated_search_01_2024"
  );

  const permissions = useAgencyHumanPermissions(agencyHumanId);

  const items = [
    sidebarItem({
      currentPage,
      navigateToPage,
      pageId: "summary",
      pageTitle: t("summary"),
      href: personPath(agencyHumanId),
      Icon: Icons.InfoCircle,
    }),
  ];
  if (permissions.mayEdit()) {
    items.push(
      sidebarItem({
        currentPage,
        navigateToPage,
        pageId: "edit",
        pageTitle: t("edit"),
        href: editPersonPath(agencyHumanId),
        Icon: Icons.Pencil,
      })
    );
  }
  if (permissions.mayViewFamilyFindingDocuments() && ffIntegratedSearch) {
    items.push(
      sidebarItem({
        currentPage,
        navigateToPage,
        pageId: "family_finding_documents",
        pageTitle: t("family_finding_documents"),
        href: familyFindingDocumentsPersonPath(agencyHumanId),
        Icon: Icons.File,
      })
    );
  }

  return <Sidebar avatar={avatar} label={label} items={items} />;
};
AgencyHumanSidebar.propTypes = {
  agencyHumanId: PropTypes.string.isRequired,
  avatar: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  label: PropTypes.string.isRequired,
};

export default AgencyHumanSidebar;
